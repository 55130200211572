import React from "react"
import Layout from "../components/layout"
import * as cn from "classnames"
import ProductPreview from "../components/layout/ProductPreview"
import ListTitle from "../components/layout/ListTitle"
import { graphql } from 'gatsby'
import NoProductInfo from "../components/layout/NoProductInfo"
import {HelmetDatoCms} from "gatsby-source-datocms"

export default class SubCategoryList extends React.Component {
    state = {
        mode: "list"
    }

    changeMode(mode) {
        this.setState({mode: mode})
    }

    render() {
        const p = this.props, { } = p
        const s = this.state, { } = s
        const category = p.data.category
        const products = p.data.product.edges.filter(({node: product}) => product.subCategory.title === category.title)
        const serwis = p.data.serwis.edges.filter(({node: product}) => product.subCategory.title === category.title)
        const automatyka = p.data.automatyka.edges.filter(({node: product}) => product.subCategory.title === category.title)
        const akcesoria = p.data.akcesoria.edges.filter(({node: product}) => product.subCategory.title === category.title)
        const sum = products.filter(n => n).length
        const sumSerwis = serwis.filter(n => n).length
        const sumAutomatyka = automatyka.filter(n => n).length
        const sumAkcesoria = akcesoria.filter(n => n).length

        return (
            <Layout className="solid">
                <HelmetDatoCms>
                    <title>Falve - {category.title}</title>
                    <meta name="description" content={category.title}/>
                </HelmetDatoCms>
                <div className="breadcrumb">
                    <span>Produkty</span>
                    <a href={`/category/${category.mainCategory.slug}`} title={category.mainCategory.title}>{category.mainCategory.title}</a>
                    <span>{category.title}</span>
                </div>
                <div className="container">
                    <ListTitle title={category.title} sum={sum} onClick={(mode) => this.changeMode(mode)} />
                    <div className={cn(s.mode)}>
                        {sum === 0 && sumAkcesoria === 0 && sumSerwis === 0 && sumAutomatyka === 0 && <NoProductInfo />}
                        {sum > 0 && products.map(({ node: product }) => (
                            <ProductPreview product={product} />
                        ))}
                        {sumAkcesoria > 0 && akcesoria.map(({ node: product }) => (
                            <ProductPreview product={product} />
                        ))}
                        {sumSerwis > 0 && serwis.map(({ node: product }) => (
                            <ProductPreview product={product} />
                        ))}
                        {sumAutomatyka > 0 && automatyka.map(({ node: product }) => (
                            <ProductPreview product={product} />
                        ))}
                    </div>
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
  query SubCategoryQuery($slug: String!) {
    category: datoCmsSubCategory(slug: { eq: $slug }) {
      title
      mainCategory {
        title
        slug
      }
    }
    product: allDatoCmsProduct {
      totalCount
      edges {
        node {
          title
          description
          slug
          subCategory {
            title
          }
          gallery {
           fluid(imgixParams: { auto: "compress" }) {
             ...GatsbyDatoCmsSizes
           }
          }
        }
      }
    }
    serwis: allDatoCmsSerwi {
      totalCount
      edges {
        node {
          title
          description
          slug
          subCategory {
            title
          }
          gallery {
           fluid(imgixParams: { auto: "compress" }) {
             ...GatsbyDatoCmsSizes
           }
          }
        }
      }
    }
    akcesoria: allDatoCmsAkcesor {
      totalCount
      edges {
        node {
          title
          description
          slug
          subCategory {
            title
          }
          gallery {
           fluid(imgixParams: { auto: "compress" }) {
             ...GatsbyDatoCmsSizes
           }
          }
        }
      }
    }
    automatyka: allDatoCmsAutomatyka {
      totalCount
      edges {
        node {
          title
          description
          slug
          subCategory {
            title
          }
          gallery {
           fluid(imgixParams: { auto: "compress" }) {
             ...GatsbyDatoCmsSizes
           }
          }
        }
      }
    }
  }
`