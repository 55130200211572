import React from "react"

export default class NoProductInfo extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <div className="empty-list">
                <p>Brak produktów w danej kategorii</p>
            </div>
        )
    }
}