import React from "react"
import * as cn from "classnames"

export default class ListTitle extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        mode: "list"
    }

    onClick(mode) {
        this.setState({mode: mode})
        this.props.onClick(mode)
    }

    render() {
        return (
            <div className="page-sub-title">
                <h3>{this.props.title}</h3>
                {this.props.sum > 0 &&
                    <div className="actions">
                        <button className={cn(this.state.mode === "list" && "active")} onClick={() => this.onClick("list")}>
                            <span className="icon icon-list"></span>
                        </button>
                        <span className="sep"></span>
                        <button className={cn(this.state.mode === "grid" && "active")} onClick={() => this.onClick( "grid")}>
                            <span className="icon icon-grid"></span>
                        </button>
                    </div>
                }
            </div>
        )
    }
}