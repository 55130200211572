import React from "react"
import {Link} from "gatsby"

export default class ProductPreview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const product = this.props.product

        return (
            <Link to={`/product/${product.slug}`} className="product">
                <div className="product__avatar">
                    {product.gallery.length == 0 &&
                        <span className="icon-no-photo"></span>
                    }
                    {product.gallery.length > 0 && product.gallery.slice(0, 1).map(({ fluid: image }, idx) => (
                        <div key={idx}>
                            {image &&
                                <img src={image.src} alt="" />
                            }
                            {!image &&
                                <span className="icon-no-photo"></span>
                            }
                        </div>
                    ))}
                </div>
                <div className="product__description">
                    <h3>{product.title}</h3>
                    <p>{product.description}</p>
                </div>
            </Link>
        )
    }
}